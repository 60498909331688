import React from 'react'
import { graphql,Link } from "gatsby"
import * as styles from "./category-page.module.css"
import CtaCard from '../../components/ctaCard/CtaCard'
import Footer from "../../components/footer/Footer"
import Nav from "../../components/Nav"

const CategoryPage = ({data}) => {

    return (
        <div>
          <Nav/>
          <div className={styles.headerBg}>
            <div className={styles.headerContainer}>

          
              <div className={styles.headerTextContainer}>
              <h1 className={styles.headerTitle} > {data.json.title} </h1>
              <h2 className={styles.headerSubTitle}>{data.json.subTitle}</h2>

              </div>
          
            </div>
          </div>
            <div className={styles.subCategoryContainer}>
              
              {data.allJson.nodes.map((e)=>{
              if(e.status !== 'Coming Soon'){
                    return (
                      <Link key={e.id} to={e.fields.slug} className={styles.content}>
                          <div className={styles.contentTitle} >{e.subcategory}</div>
                          <div className={styles.contentBody}>
                          {e.subTitle}
                        </div>
                      </Link>
                      )
                  }
                }
          
              )}
              {data.allJson.nodes.map((e)=>{
                  if(e.status === 'Coming Soon'){
                    return (
                      <Link key={e.id} className={styles.content} >
                          <div className={styles.contentTitle}>{e.subcategory}</div>
                          <div className={styles.contentBody}>
                            Coming Soon
                          </div>
                      </Link>
                      )
                    }
                  }
          
              )}
            </div>
            <CtaCard/>
            <Footer/>
        </div>
    )
}

export default CategoryPage

export const query = graphql`
query($slug:String!){
    json(fields:{slug:{eq:$slug}}){
      title
      subTitle
      fields{
        slug
      }
    }
    allJson(filter:{fields:{slug:{regex:$slug}},templateType:{eq:"resources"}}){
      nodes{
        id
        title
        subTitle
        subcategory
        status
        fields{
          slug
        }
      }
    }
  }
    
`
